import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-five'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import PaintingAndDecoratingArea from '../containers/painting-and-decorating/painting-and-decorating-area'
import ContactArea from '../containers/global/contact-area/contact-two'

const PaintingAndDecoratingPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="Painting and Decorating" />
    <Header/>
    <PageHeader 
        pageContext={pageContext} 
        location={location}
        title="Painting and Decorating"
    />
    <main className="site-wrapper-reveal">
        <PaintingAndDecoratingArea/>
        <ContactArea/>
    </main>
    <Footer/>
  </Layout>
)
 
export default PaintingAndDecoratingPage
 